
setImageSource();

window.onresize = function(event) {
    setImageSource();
};

function setImageSource(){
    // Target all html with this class
    var x = document.getElementsByClassName("js-scalable-image");
    // get the curent window width
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    var largeImageDisplayWidthDefault  = 1200;
    var mediumImageDisplayWidthDefault = 960;
    var smallImageDisplayWidthDefault  = 500;

    // Loop through all of the items with the specified class
    for (i = 0; i < x.length; i++) {
        var htmlTagType      = x[i].tagName;
        var largeImageSrc    = undefined;
        var largeImageWidth  = undefined;
        var mediumImageSrc   = undefined;
        var mediumImageWidth = undefined;
        var smallImageSrc    = undefined;
        var smallImageWidth  = undefined;
        var newImageSrc      = "";

        // Check if largeimage attribute exists
        if (typeof x[i].dataset.imageLarge !== 'undefined') {
            var largeImage  = x[i].dataset.imageLarge.split(',');
            if (typeof largeImage[1] === 'undefined') {
                console.error("Large image screen size not provided");
                largeImageWidth = largeImageDisplayWidthDefault;
            } else {
                largeImageWidth = largeImage[1];
            }
            largeImageSrc = largeImage[0];
        }

        // Check if medium image attribute exists
        if (typeof x[i].dataset.imageMedium !== 'undefined') {
            var mediumImage = x[i].dataset.imageMedium.split(',');
            if (typeof mediumImage[1] === 'undefined') {
                console.error("Medium image screen size not provided");
                mediumImageWidth = mediumImageDisplayWidthDefault;
            } else {
                mediumImageWidth = mediumImage[1];
            }
            mediumImageSrc = mediumImage[0];
        }

        // Check if small image attribute exists
        if (typeof x[i].dataset.imageSmall !== 'undefined') {
            var smallImage  = x[i].dataset.imageSmall.split(',');
            if (typeof smallImage[1] === 'undefined') {
                console.error("Small image screen size not provided");
                smallImageWidth = smallImageDisplayWidthDefault;
            } else {
                smallImageWidth = smallImage[1];
            }
            smallImageSrc = smallImage[0];
        }

        if (typeof smallImageWidth !== 'undefined' && typeof mediumImageWidth !== 'undefined' && typeof largeImageWidth !== 'undefined') {
            if(w <= smallImageWidth) {
                newImageSrc = smallImageSrc;
            } else if(w > smallImageWidth && w <= mediumImageWidth) {
                newImageSrc = mediumImageSrc;
            } else if(w > mediumImageWidth) {
                newImageSrc = largeImageSrc;
            }
        } else if(typeof smallImageWidth !== 'undefined' && typeof mediumImageWidth !== 'undefined') {
            if(w <= smallImageWidth) {
                newImageSrc = smallImageSrc;
            } else if(w > smallImageWidth) {
                newImageSrc = mediumImageSrc;
            }
        } else if(typeof smallImageWidth !== 'undefined' && typeof largeImageWidth !== 'undefined'){
            if(w <= smallImageWidth) {
                newImageSrc = smallImageSrc;
            } else if(w > smallImageWidth ) {
                newImageSrc = largeImageSrc;
            }
        } else if(typeof mediumImageWidth !== 'undefined' && typeof largeImageWidth !== 'undefined'){
            if(w <= mediumImageWidth) {
                newImageSrc = mediumImageSrc;
            } else if(w > mediumImageWidth) {
                newImageSrc = largeImageSrc;
            }
        } else if(typeof smallImageWidth !== 'undefined') {
            newImageSrc = smallImageSrc;
        } else if(typeof mediumImageWidth !== 'undefined') {
            newImageSrc = mediumImageSrc;
        } else if(typeof largeImageWidth !== 'undefined') {
            newImageSrc = largeImageSrc;
        }

        if(htmlTagType == 'IMG') {
            x[i].src = newImageSrc;
        } else {
            x[i].style.backgroundImage    = "url('" + newImageSrc + "')";
        }
    }
}