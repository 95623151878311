$(window).on('load', function() {
	"use strict";
    $('.mm-slider').flexslider({
        namespace: 'mm-',
        selector: '.mm-slides > .mm-slide',
        pausePlay: true,
        pauseText: '❚❚',
        playText: '►',

		start: function(slider) {
            // Check if only one slide, remove controls if yes
            if ($('.mm-slide').length == 1) {
                $('.mm-control-nav, .mm-direction-nav, .mm-pauseplay').remove();
            }

            // Move controls to control container
            $('.mm-control-nav, .mm-direction-nav, .mm-pauseplay').appendTo('.mm-controls');

            // Set all slides to aria-hidden on init
            $('.mm-slide').each(function(){
                $(this).attr('aria-hidden', 'true');
            });

            // Set active slide to not aria-hidden
            $(slider).find('.mm-active-slide').attr('aria-hidden', 'false');

            // Add data attribute of data-mm-loaded to the marketing message
            $('section.marketing-message').attr('data-mm-loaded', 'true');
        },
        before: function(slider) {
            // Before slider moves to new slide, set all to aria-hidden
            $(slider).find(".mm-active-slide").each(function(){
                $(this).attr('aria-hidden', 'true');
            });
        },
        after: function(slider) {
            // After slider moves to new slide, set active slide to not aria-hidden
            $(slider).find('.mm-active-slide').attr('aria-hidden', 'false');
        }
    });
});